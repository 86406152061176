<template>

<div class="select" :class="{'is-error': error}">

	<app-icon s="error" v-if="error" class="error" />
	
	<select ref="select" class="select-input" v-bind:value="value" v-on:change="onChange">
		<option value="" :selected="!value">{{ input.label }}</option>
		<option :value="option.id" v-for="option in input.options" :key="option.id" :selected="value === option.id">{{ option.name }}</option>
	</select>

	<app-icon s="chevronDown" class="select-icon" />

</div>

</template>

<script>

export default {

	props: ['input', 'value', 'error'],

	methods: {

		onChange: function() {

			if (this.$refs.select.selectedIndex > 0) {

				this.$emit('input', this.input.options[this.$refs.select.selectedIndex - 1].id)
				
			} else {

				this.$emit('input', '')

			}

		}

	}

}

</script>

<style scoped>

.select {
	margin-bottom: 20px;
}

.select-input {
	background-color: #f5f5f5;
	padding: 0px 20px;
	height: 46px;
	line-height:46px;
	color: #1A234C;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 1.4px;
	border-radius: 23px;
	cursor: pointer;
	width: 100%;
}

.select-input::placeholder {
	color: #1A234C;
	font-size: 14px;
	font-weight: 500;
}

.select-icon {
	color: #1A234C;
	font-size: 18px;
	z-index: 2;
	position: absolute;
	right: 20px;
	top: 16px;
}

</style>
